.carousel-container {
    overflow: hidden;
    width: 100vw;
    background-color: '#191F2A';
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.carousel-content {
    display: flex;
    width: max-content;
    animation: scroll 10s linear infinite;
    /* Increased time for smoother scroll */
    gap: 40px;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-50%);
        /* Adjusted for seamless transition */
    }
}